// src/utils/api.js

// export const baseUrl = 'http://localhost:5001/'
export const baseUrl = 'http://52.66.200.78:5000/'

export const fetchTableData = async (type) => {
  let url = ''

  // Determine the API URL based on the type
  switch (type) {
    case 'user':
      url = baseUrl + 'get-all-users'
      break
    case 'product':
      url = baseUrl + 'products'
      break
    case 'order':
      url = baseUrl + 'get-all-orders'
      break
    case 'makeupArtist':
      url = baseUrl + 'makeup-artists'
      break
    case 'categories':
      url = baseUrl + 'categories'
      break
    case 'makeupArtistCategories':
      url = baseUrl + 'makeup-categories'
      break
    case 'orders':
      url = baseUrl + 'orders'
      break;
    default:
      throw new Error('Invalid table type')
  }

  try {
    const response = await fetch(url)
    if (!response.ok) {
      throw new Error('Failed to fetch data')
    }
    const result = await response.json()

    console.log(result)

    // Format the data here before returning it
    const formattedData = (type == 'orders' ? result.orders : result).map((item) => {
      switch (type) {
        case 'product':
          return {
            id: item?._id,
            name: item?.name,
            description: item?.description,
            price: item?.price,
            category: item?.category?.name, // Accessing nested category name
            stock: item?.stock,
            ratings: item?.ratings?.toFixed(1),
            images: item?.images,
            createdAt: new Date(item?.createdAt).toLocaleString() // Optional: Format date
          }
        case 'user':
          return {
            id: item._id,
            username: item.username || 'N/A',
            email: item.email || 'N/A',
            image: item.image || '',
            status: item.role === 'active' ? 'active' : 'inactive',
            age: item.age || 'N/A',
            createdAt: item.createdAt || 'N/A',
            role: item.role || 'N/A',
            mobileNumber: item.mobileNumber || 'N/A'
          }
        case 'makeupArtist':
          return {
            id: item._id,
            fullName: item.fullName,
            email: item.email,
            phoneNumber: item.phoneNumber,
            appreciation: item.appreciation,
            tasksCompleted: item.tasksCompleted,
            rewards: item.rewards,
            experience: item.experience,
            services: item.services.join(', '), // Join services array as a string
            availability: item.availability
              .map(
                (avail) => `${avail.day}: ${avail.startTime} - ${avail.endTime}`
              )
              .join(', '), // Format availability
            location: item?.location[0]?.city,
            // appointments: item.appointments.length,
            category: item.category.map((cat) => cat.name).join(', '), // Join categories
            ratings: item.ratings.toFixed(1),
            createdAt: new Date(item.createdAt).toLocaleString() // Format date
          }
        case 'makeupArtistCategories':
          return {
            id: item._id,
            name: item.name,
            description: item.description,
            createdAt: item.createdAt
          }
        case 'categories':
          return {
            id: item._id,
            name: item.name,
            description: item.description,
            createdAt: item.createdAt,
            categoryImage:item.categoryImage
          }
        case 'orders':
          console.log(item)
          return {
            id: item._id,
            customerName: item.customer.username,
            totalAmount: `₹ ${item.totalAmount}`,
            products: item.products
              .map((p) => `${p.product.name} (x${p.quantity})`)
              .join(', '),
            paymentStatus: item.paymentStatus,
            orderStatus: item.orderStatus,
            createdAt: new Date(item.createdAt).toLocaleString() // Format date
          }
        // Add other cases for order, category, etc.
        default:
          return item // Fallback for unhandled cases
      }
    })

    return formattedData // Return the formatted data
  } catch (error) {
    console.error('Error fetching data:', error)
    throw error
  }
}
