/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable react/jsx-props-no-spreading */
import CloseButton from '@mui/icons-material/Close';
import DriveFolderUploadIcon from '@mui/icons-material/DriveFolderUpload';
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import Input from '../../Components/Input/Input';
import Navbar from '../../Components/Navbar/Navbar';
import Sidebar from '../../Components/Sidebar/Sidebar';
import noImage from '../../Images/photo-camera.png';
import { baseUrl } from '../../utils/api';
import { Box } from '@mui/material';
import './New.scss';

function AddNew({ inputs, titlee, type }) {
  const navigate = useNavigate();

  const initialInputValues = {
    USER: {
      username: '',
      name: '',
      email: '',
      password: '',
      address: ''
    },
    PRODUCT: {
      name: '',
      description: '',
      price: '',
      category: '',
      stock: '',
      productType: ''
    },
    BLOG: {
      title: '',
      description: '',
      tags: ''
    },
    CATEGORIES: {
      title: '',
      description: ''
    },
    MAKEUP_ARTIST: {
      fullName: '',
      email: '',
      phoneNumber: '',
      appreciation: '',
      tasksCompleted: '',
      rewards: '',
      experience: '',
      services: [],
      availability: [
        {
          day: '',
          startTime: '',
          endTime: ''
        }
      ],
      location: '',
      category: []
    }
  };

  const [userInp, setUserInp] = useState(initialInputValues[type] || {});
  const [files, setFiles] = useState([]);
  const [imagePreviews, setImagePreviews] = useState([]);

  const handleChange = (e) => {
    setUserInp({ ...userInp, [e.target.name]: e.target.value });
  };

  const handleFileChange = (e) => {
    const selectedFiles = Array.from(e.target.files);
    const totalSize = selectedFiles.reduce((sum, file) => sum + file.size, 0);

    if (totalSize > 10 * 1024 * 1024) { // 10 MB limit
      toast.error("Please upload files smaller than 10 MB");
      return;
    }

    const newFiles = [...files, ...selectedFiles].slice(0, 5); // Limit to 5 files
    setFiles(newFiles);

    const previews = newFiles.map((file) => URL.createObjectURL(file));
    setImagePreviews(previews);
  };

  const removeImage = (index) => {
    const newFiles = files.filter((_, i) => i !== index);
    const newPreviews = imagePreviews.filter((_, i) => i !== index);
    setFiles(newFiles);
    setImagePreviews(newPreviews);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const formData = new FormData();
    files.forEach((file) => formData.append(type === 'PRODUCT' ? 'media' : 'categoryImage', file));

    // Append other input fields
    Object.entries(userInp).forEach(([key, value]) => {
      formData.append(key, value);
    });

    try {
      const url = type === 'PRODUCT' ? `${baseUrl}products` : `${baseUrl}categories`;
      const response = await fetch(url, {
        method: 'POST',
        body: formData
      });

      if (response.ok) {
        toast.success(`${type === 'PRODUCT' ? 'Product' : 'Category'} added successfully!`);
        navigate(`/${type === 'PRODUCT' ? 'products' : 'categories'}`);
      } else {
        toast.error('Failed to add product!');
      }
    } catch (error) {
      console.error('Error adding product:', error);
      toast.error('An error occurred while adding the product!');
    }
  };

  return (
    <div className="add_new">
      <div className="home_sidebar">
        <Sidebar />
      </div>

      <div className="new_page">
        <Navbar />

        <div className="new_page_main">
          <div className="new_page_content">
            <div className="image">
              <p className="add_new_user">{titlee}</p>
              <label htmlFor="file" style={{ display: "flex", flexDirection: "row", gap: 5, alignItems: "center", marginTop: 5 }}>
                Upload Product Images:{' '}
                <DriveFolderUploadIcon className="file_icon" />
              </label>
              {imagePreviews.length > 0 ? (
                imagePreviews.map((preview, index) => (
                  <div key={index} style={styles.imageWrapper}>
                    <img src={preview} alt={`preview-${index}`} style={styles.image} />
                    <button
                      onClick={() => removeImage(index)}
                      style={styles.removeButton}
                    >
                      <CloseButton className="close" />
                    </button>
                  </div>
                ))
              ) : (
                <img src={noImage} alt="" />
              )}
            </div>

            <form onSubmit={handleSubmit} className="form" style={{ display: 'flex', flexDirection: 'column' }}>
              <div style={{ display: 'flex', flexDirection: 'column', gap: 20 }}>
                <input
                  type="file"
                  name="file"
                  id="file"
                  multiple
                  accept="image/*"
                  style={{ display: 'none' }}
                  onChange={handleFileChange}
                />
                <label htmlFor="file" style={styles.uploadButton}>
                  <DriveFolderUploadIcon /> Choose Files
                </label>

                <div style={{ display: 'flex', flexDirection: 'row', flexWrap: 'wrap', gap: 30 }}>
                  {inputs.map((detail) => (
                    <div style={{ flex: '0 0 calc(50% - 15px)' }} key={detail.id}>
                      <Input
                        {...detail}
                        value={userInp[detail.name]}
                        onChange={handleChange}
                      />
                    </div>
                  ))}
                </div>

                <button type="submit" className="submit_btn">
                  Submit
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
}

const styles = {
  imageWrapper: {
    position: "relative",
    width: "100px",
    height: "100px",
    marginBottom: "10px"
  },
  image: {
    width: "100%",
    height: "100%",
    objectFit: "cover",
    borderRadius: "8px",
  },
  removeButton: {
    position: "absolute",
    top: "5px",
    right: "5px",
    backgroundColor: "red",
    color: "white",
    border: "none",
    borderRadius: "50%",
    width: "25px",
    height: "25px",
    cursor: "pointer",
  },
  uploadButton: {
    marginTop: "10px",
    padding: "10px 20px",
    fontSize: "16px",
    cursor: "pointer",
  },
};

export default AddNew;
