/* eslint-disable react/no-unknown-property */
/* eslint-disable react/jsx-props-no-spreading */
import React, { useState, useEffect } from 'react';
import './input.scss';
import { fetchTableData } from '../../utils/api';

function Input(inpDetails) {
    const [focus, setFocus] = useState(false);
    const { lable, onChange, type, errorMsg, ...detail } = inpDetails;

    const handleBlur = () => {
        setFocus(true);
    };

    const [userData, setUserData] = useState([]);
    const [loading, setLoading] = useState(true);

    // Fetch data based on type (user, product, etc.)
    useEffect(() => {
        const fetchData = async () => {
            setLoading(true);
            try {
                const formattedData = await fetchTableData('categories');
                setUserData(formattedData);
            } catch (error) {
                console.error('Error fetching user data:', error);    
            }
            setLoading(false);
        };

        fetchData();
    }, [type]);

    return (
        <div className="input_component">
            <label>{lable}</label>
            
            {/* Conditionally render dropdown if label is "Category" */}
            {lable === 'Category' ? (
                <select
                    className="input_field"
                    {...detail}
                    onChange={onChange}
                    onBlur={handleBlur}
                    onFocus={() => detail.name === 'password' && setFocus(true)}
                    focused={focus.toString()}
                >
                    <option value="">Select Category</option>
                    {userData.map((item, index) => (
                        <option key={index} value={item.id}>{item.name}</option>
                    ))}
                </select>
            ) : ( lable === 'Product Type' ? (
                <select
                    className="input_field"
                    {...detail}
                    onChange={onChange}
                    onBlur={handleBlur}
                    onFocus={() => detail.name === 'password' && setFocus(true)}
                    focused={focus.toString()}
                >
                    <option value="">Select Product</option>
                    <option value={"product"}>{"Product"}</option>
                    <option value={"course"}>{"Course"}</option>
                </select>
            ) :
                <input
                    className="input_field"
                    {...detail}
                    onChange={onChange}
                    onBlur={handleBlur}
                    onFocus={() => detail.name === 'password' && setFocus(true)}
                    focused={focus.toString()}
                    type={type}
                />
            )}

            <span>{errorMsg}</span>
        </div>
    );
}

export default Input;
