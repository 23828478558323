/* eslint-disable no-constant-condition */
/* eslint-disable no-nested-ternary */
import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import DataTable from '../../Components/DataTable/DataTable';
import Navbar from '../../Components/Navbar/Navbar';
import Sidebar from '../../Components/Sidebar/Sidebar';
import TableList from '../../Components/TableList/TableList';
import { fetchTableData } from '../../utils/api'; // Import the API utility
import CircularProgress from '@mui/material/CircularProgress'; // Import CircularProgress
import './userlists.scss';

let type = 'user'

function Lists() {
    const [userData, setUserData] = useState([]);
    const [loading, setLoading] = useState(true);

    // Fetch data based on type (user, product, etc.)
    useEffect(() => {
        const fetchData = async () => {
            setLoading(true);
            try {
                const formattedData = await fetchTableData(type);
                setUserData(formattedData);
            } catch (error) {
                console.error('Error fetching user data:', error);    
            }
            setLoading(false);
        };

        fetchData();
    }, [type]);

    return (
        <div className="list_page">
            <div className="home_sidebar">
                <Sidebar />
            </div>

            <div className="list_page_main">
                <Navbar />

                {/* mui data table */}
                {loading ? (
                    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
                        <CircularProgress /> {/* MUI CircularProgress */}
                    </div>
                ) : (
                    <div className="data_table">
                        {/* <div className="btnn" style={{ display: 'flex', justifyContent: 'end', alignItems: 'center' }}>
                            <Link
                                to={`/users/addnew`}
                                style={{ textDecoration: 'none' }}
                            >
                                <button type="button">Add New user</button>
                            </Link>
                        </div> */}

                        <DataTable userData={userData} type={'user'} />
                    </div>
                )}
            </div>
        </div>
    );
}

export default Lists;
