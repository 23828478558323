import SearchIcon from '@mui/icons-material/Search'
import Button from '@mui/material/Button'
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import DialogContentText from '@mui/material/DialogContentText'
import DialogTitle from '@mui/material/DialogTitle'
import { DataGrid } from '@mui/x-data-grid'
import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import { baseUrl } from '../../utils/api'
import './datatable.scss'

function DataTable({ userData, type }) {
  const [data, setData] = useState(userData)
  const [searchQuery, setSearchQuery] = useState('')
    const [openDialog, setOpenDialog] = useState(false)
  const [deleteId, setDeleteId] = useState(null)

  const handleOpenDialog = (id) => {
    setDeleteId(id)
    setOpenDialog(true)
  }

  const handleCloseDialog = () => {
    setOpenDialog(false)
    setDeleteId(null)
  }

  const handleConfirmDelete = () => {
    setData(data.filter((item) => item.id !== deleteId))
    handleDeleteAPI()
    handleCloseDialog()
  }

  const handleDeleteAPI = async() =>{
    const apis =
      type === 'user' ? "user"
      : type === 'makeupArtist' ? "user"
      : type === 'makeupArtistCategories' ? "makeup-categories"
      : type === 'categories' ? "categories"
      : type === 'orders' ? "orders" : "products"

      try {
        const response = await fetch(`${baseUrl}${apis}/${deleteId}`, {
          method: "DELETE",
          headers: {
            "Content-Type": "application/json",
          },
          // body: JSON.stringify({
          //   id: deleteId,
          // }),
        });
        
        if (response.ok) {
          const data = await response.json();
          console.log(data)
        } else {
          const errorData = await response.json();
          console.error("delete failed:", errorData);
        }
      } catch (error) {
        console.error("Error during delete:", error);
      }
  }

  const handleDlt = (id) => {
    setData(data.filter((item) => item.id !== id))
    handleDeleteAPI()
  }

  const handleSearch = (event) => {
    const value = event.target.value.toLowerCase()
    setSearchQuery(value)
    setData(
      userData.filter((item) =>
        Object.values(item).some(
          (field) => field && field.toString().toLowerCase().includes(value)
        )
      )
    )
  }

  // Define columns for 'user' type
  const userColumns = [
    {
      field: 'id',
      headerName: 'ID',
      flex: 1,
      minWidth: 150,
      cellClassName: 'wrapContent'
    },
    {
      field: 'username',
      headerName: 'Username',
      flex: 1,
      minWidth: 150,
      cellClassName: 'wrapContent'
    },
    {
      field: 'email',
      headerName: 'Email',
      flex: 1,
      minWidth: 150,
      cellClassName: 'wrapContent'
    },
    {
      field: 'mobileNumber',
      headerName: 'Mobile Number',
      flex: 1,
      minWidth: 150,
      cellClassName: 'wrapContent'
    },
    {
      field: 'role',
      headerName: 'Role',
      flex: 1,
      minWidth: 150,
      cellClassName: 'wrapContent'
    },
    {
      field: 'createdAt',
      headerName: 'Created At',
      flex: 1,
      minWidth: 180,
      cellClassName: 'wrapContent',
      valueFormatter: (params) => new Date(params.value).toLocaleString()
    },
    {
      field: 'action',
      headerName: 'Action',
      flex: 1,
      minWidth: 150,
      renderCell: (params) => (
        <div className="actionn">
          {/* <Link to={params.row.id}>
            <button type="button" className="view_btn">
              View
            </button>
          </Link> */}
          {/* <div className="actionn"> */}
          <button
            type="button"
            className="delete_btn"
            onClick={() => handleOpenDialog(params.row.id)}
          >
            Delete
          </button>
        </div>
      )
    }
  ]

  // Define columns for 'makeup artist' type
  const makeupArtistColumns = [
    {
      field: 'id',
      headerName: 'ID',
      flex: 1,
      minWidth: 120,
      cellClassName: 'wrapContent'
    },
    {
      field: 'fullName',
      headerName: 'Full Name',
      flex: 1,
      minWidth: 120,
      cellClassName: 'wrapContent'
    },
    {
      field: 'email',
      headerName: 'Email',
      flex: 1,
      minWidth: 120,
      cellClassName: 'wrapContent'
    },
    {
      field: 'phoneNumber',
      headerName: 'Phone Number',
      flex: 1,
      minWidth: 120,
      cellClassName: 'wrapContent'
    },
    {
      field: 'experience',
      headerName: 'Experience (years)',
      flex: 1,
      minWidth: 120,
      cellClassName: 'wrapContent'
    },
    {
      field: 'services',
      headerName: 'Services',
      flex: 2,
      minWidth: 120,
      cellClassName: 'wrapContent'
    },
    {
      field: 'location',
      headerName: 'Location',
      flex: 1,
      minWidth: 120,
      cellClassName: 'wrapContent'
    },
    {
      field: 'category',
      headerName: 'Category',
      flex: 1,
      minWidth: 120,
      cellClassName: 'wrapContent'
    },
    {
      field: 'ratings',
      headerName: 'Ratings',
      flex: 1,
      minWidth: 120,
      cellClassName: 'wrapContent'
    },
    {
      field: 'action',
      headerName: 'Action',
      flex: 1,
      minWidth: 150,
      renderCell: (params) => (
        <div className="actionn">
          <Link to={params.row.id}>
            <button type="button" className="view_btn">
              View
            </button>
          </Link>
          <button
            type="button"
            className="delete_btn"
            // onClick={() => handleDlt(params.row.id)}
            onClick={() => handleOpenDialog(params.row.id)}
          >
            Delete
          </button>
        </div>
      )
    }
  ]

  // Define columns for 'product' type
  const productColumns = [
    {
      field: 'id',
      headerName: 'ID',
      flex: 1,
      minWidth: 100,
      cellClassName: 'wrapContent'
    },
    {
      field: 'name',
      headerName: 'Name',
      flex: 1,
      minWidth: 200,
      cellClassName: 'wrapContent'
    },
    {
      field: 'description',
      headerName: 'Description',
      flex: 2,
      minWidth: 220,
      cellClassName: 'wrapContent'
    },
    { field: 'price', headerName: 'Price', flex: 1, minWidth: 100 },
    {
      field: 'category',
      headerName: 'Category',
      flex: 1,
      minWidth: 120,
      cellClassName: 'wrapContent'
    },
    { field: 'stock', headerName: 'Stock', flex: 1, minWidth: 80 },
    { field: 'ratings', headerName: 'Ratings', flex: 1, minWidth: 80 },
    {
      field: 'createdAt',
      headerName: 'Created At',
      flex: 1,
      minWidth: 150,
      cellClassName: 'wrapContent',
      // valueFormatter: (params) => new Date(params.value).toLocaleString()
    },
    {
      field: 'action',
      headerName: 'Action',
      flex: 1,
      minWidth: 150,
      renderCell: (params) => (
        <div className="actionn">
          <Link to={params.row.id}>
            <button type="button" className="view_btn">
              View
            </button>
          </Link>
          <button
            type="button"
            className="delete_btn"
            onClick={() => handleOpenDialog(params.row.id)}
            // onClick={() => handleDlt(params.row.id)}
          >
            Delete
          </button>
        </div>
      )
    }
  ]

  const makeupArtistCategoryColumns = [

    {
      field: 'categoryImage',
      headerName: 'Category Image',
      minWidth: 120,
      renderCell: (params) => params.row.categoryImage ? <img style={{height:75,width:75}} src={params.row.categoryImage} alt=""/>:<div style={{marginLeft:10}}>N/A</div>, // renderCell will render the component
    },
    
    { field: 'id', headerName: 'ID', flex: 1, minWidth: 120 },
    {
      field: 'name',
      headerName: 'Name',
      flex: 1,
      minWidth: 120,
      cellClassName: 'wrapContent'
    },
    {
      field: 'description',
      headerName: 'Description',
      flex: 2,
      minWidth: 150,
      cellClassName: 'wrapContent'
    },
    {
      field: 'createdAt',
      headerName: 'Created At',
      flex: 1,
      minWidth: 150,
      valueFormatter: (params) => new Date(params.value).toLocaleString()
    },
    
    {
      field: 'action',
      headerName: 'Action',
      flex: 1,
      minWidth: 150,
      renderCell: (params) => (
        <div className="actionn">
          {/* <Link to={params.row.id}>
            <button type="button" className="view_btn">
              View
            </button>
          </Link> */}
          <button
            type="button"
            className="delete_btn"
            // onClick={() => handleDlt(params.row.id)}
            onClick={() => handleOpenDialog(params.row.id)}
          >
            Delete
          </button>
        </div>
      )
    }
  ]

  const orderColumns = [
    {
      field: 'id',
      headerName: 'Order ID',
      flex: 1,
      minWidth: 120,
      cellClassName: 'wrapContent'
    },
    {
      field: 'customerName',
      headerName: 'Customer Name',
      flex: 1,
      minWidth: 150,
      cellClassName: 'wrapContent'
    },
    {
      field: 'products',
      headerName: 'Product',
      flex: 1,
      minWidth: 150,
      cellClassName: 'wrapContent'
    },
    {
      field: 'totalAmount',
      headerName: 'Total Price',
      flex: 1,
      minWidth: 120,
      cellClassName: 'wrapContent'
    },
    {
      field: 'createdAt',
      headerName: 'Order Date',
      flex: 1,
      minWidth: 150,
      cellClassName: 'wrapContent',
      valueFormatter: (params) => new Date(params.value).toLocaleString()
    },
    {
      field: 'orderStatus',
      headerName: 'Status',
      flex: 1,
      minWidth: 120,
      cellClassName: 'wrapContent'
    },
    {
      field: 'action',
      headerName: 'Action',
      flex: 1,
      minWidth: 150,
      cellClassName: 'wrapContent',
      renderCell: (params) => {
        console.log(params.id)
        return (
        <div className="actionn">
          <Link to={`/orders/${params.id}`}>
            <button type="button" className="view_btn">
              View
            </button>
          </Link>
          <button
            type="button"
            className="delete_btn"
            // onClick={() => handleDlt(params.row.id)}
            onClick={() => handleOpenDialog(params.row.id)}
          >
            Delete
          </button>
        </div>
      )}
    }
  ]

  // Select columns based on the type prop
  const columns =
    type === 'user'
      ? userColumns
      : type === 'makeupArtist'
      ? makeupArtistColumns
      : type === 'makeupArtistCategories' || type === 'categories'
      ? makeupArtistCategoryColumns
      : type === 'orders'
      ? orderColumns
      : productColumns

  return (
    <div className="data_table">
      <div className="search">
        <input
          type="text"
          placeholder="Search..."
          value={searchQuery}
          onChange={handleSearch}
          className="search_input"
        />
        <SearchIcon className="search_icon" />
      </div>
      <DataGrid
        className="data_grid"
        rows={data}
        columns={columns}
        pageSize={10}
        getRowHeight={() => 'auto'}
        rowsPerPageOptions={[10]}
        // checkboxSelection
        getRowId={(row) => row.id}
      />

      {/* Confirmation Dialog */}
      <Dialog
        open={openDialog}
        onClose={handleCloseDialog}
      >
        <DialogTitle>Confirm Delete</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Are you sure you want to delete this item? This action cannot be undone.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDialog} color="primary">
            Cancel
          </Button>
          <Button onClick={handleConfirmDelete} color="secondary">
            Delete
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  )
}

export default DataTable
