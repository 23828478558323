/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable react/jsx-props-no-spreading */
import {
  Box,
  Card,
  CardContent,
  CircularProgress,
  Grid,
  Typography
} from '@mui/material'
import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import Navbar from '../../Components/Navbar/Navbar'
import Sidebar from '../../Components/Sidebar/Sidebar'
import { baseUrl } from '../../utils/api'
import './OrderDetailPage.scss'

function OrderDetailPage() {
  const { orderId } = useParams()
  const [order, setOrder] = useState(null)
  const [loading, setLoading] = useState(true)
  const [error, setError] = useState(null)
  const [focus, setFocus] = useState(false);
  const handleBlur = () => {
    setFocus(true);
};
const [orderStatus, setOrderStatus] = useState('Processing'); // Declare a state variable...

  useEffect(() => {
    const fetchOrder = async () => {
      try {
        const response = await fetch(`${baseUrl}orders/${orderId}`)
        if (!response.ok) {
          throw new Error('Network response was not ok')
        }
        const data = await response.json()
        setOrder(data.order)
        setOrderStatus(data.order.orderStatus)
      } catch (err) {
        setError(err.message)
      } finally {
        setLoading(false)
      }
    }

    fetchOrder()
  }, [orderId])

  if (loading) {
    return <CircularProgress />
  }

  const handleChange = (e) => {
    console.log("handleChange",e,e.target.value)
    setOrderStatus(e.target.value)
    if(e.target.value != orderStatus){
      updateOrderStatus(e.target.value)
    }
    // setUserInp({ ...userInp, [e.target.name]: e.target.value })
  }

  const updateOrderStatus = async (value) => {
    try {
      const response = await fetch(`${baseUrl}orders/${orderId}/status`, {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          orderStatus: value,
        }),
      })
      if (!response.ok) {
        throw new Error('Network response was not ok')
      }
      const data = await response.json()
    } catch (err) {
      setError(err.message)
    } finally {
      setLoading(false)
    }
  }

  return (
    <div className="details">
      <div className="home_sidebar">
        <Sidebar />
      </div>

      <div className="detail_page_main">
        <Navbar />

        <Box sx={{ padding: '20px' }}>
          {error ? (
            <Typography variant="body1" color="error">
              {`Error: ${error}`}
            </Typography>
          ) : (
            <Grid container spacing={3}>
              {/* Customer Information */}
              <Grid item xs={12} md={6}>
                <Card className="order_card">
                  <CardContent>
                    <Typography variant="h5" component="h2">
                      Customer Details
                    </Typography>
                    <Typography variant="body1">
                      <strong>Name:</strong> {order.customer.username}
                    </Typography>
                    <Typography variant="body1">
                      <strong>Email:</strong> {order.customer.email}
                    </Typography>
                    <Typography variant="body1">
                      <strong>Mobile:</strong> {order.customer.mobileNumber}
                    </Typography>
                    <img
                      src={order.customer.profileImage}
                      alt="Customer Profile"
                      className="profile_image"
                      style={{ maxWidth: '100px', marginTop: '10px' }}
                    />
                  </CardContent>
                </Card>
              </Grid>

              {/* Shipping Address */}
              <Grid item xs={12} md={6}>
                <Card className="order_card">
                  <CardContent>
                    <Typography variant="h5" component="h2">
                      Shipping Address
                    </Typography>
                    <Typography variant="body1">
                      <strong>Street:</strong> {order.shippingAddress.street}
                    </Typography>
                    <Typography variant="body1">
                      <strong>City:</strong> {order.shippingAddress.city}
                    </Typography>
                    <Typography variant="body1">
                      <strong>State:</strong> {order.shippingAddress.state}
                    </Typography>
                    <Typography variant="body1">
                      <strong>Postal Code:</strong>{' '}
                      {order.shippingAddress.postalCode}
                    </Typography>
                    <Typography variant="body1">
                      <strong>Country:</strong> {order.shippingAddress.country}
                    </Typography>
                  </CardContent>
                </Card>
              </Grid>

              {/* Product Details */}
              <Grid item xs={12}>
                <Card className="order_card">
                  <CardContent>
                  

                        <Grid container>
                      <Grid item xs={6}> 
                            
                    <Typography variant="h5" sx={{ whiteSpace: 'pre-line'}} component="h2">
                      Product Details
                    </Typography>
                      </Grid>
                        <Grid item xs={6}>
                        <select
                        className="input_field"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={orderStatus}
                        onFocus={() => setFocus(true)}
                        focused={focus.toString()}
                        >
                          <option value="Processing">Processing</option>
                          <option value="Shipped">Shipped</option>
                          <option value="Delivered">Delivered</option>
                          <option value="Cancelled">Cancelled</option>
                        </select>
                        </Grid>
                    </Grid>
                  
                    
                    {order.products.map((item, index) => (
                      <Box
                        key={index}
                        className="product_item"
                        sx={{ marginBottom: '10px' }}
                      >
                        <Typography variant="h6">
                          {item.product.name}
                        </Typography>
                        <Typography variant="body1">
                          <strong>Quantity:</strong> {item.quantity}
                        </Typography>
                        <Typography variant="body1">
                          <strong>Price:</strong> ${item.priceAtPurchase}
                        </Typography>
                        <Typography variant="body1">
                          <strong>Total:</strong> $
                          {item.quantity * item.priceAtPurchase}
                        </Typography>
                        <div className="product_images_gallery">
                          {item.product.media.map((image, idx) => (
                            <img
                              key={idx}
                              src={image}
                              alt={`${item.product.name} ${idx + 1}`}
                              className="product_image"
                              style={{ maxWidth: '100px', marginRight: '10px' }}
                            />
                          ))}
                        </div>
                      </Box>
                    ))}
                    <Typography variant="h6">
                      <strong>Total Amount:</strong> ${order.totalAmount}
                    </Typography>
                  </CardContent>
                </Card>
              </Grid>

              {/* Order Status */}
              <Grid item xs={12}>
                <Card className="order_card">
                  <CardContent>
                    <Typography variant="h5" component="h2">
                      Order Status
                    </Typography>
                    <Typography variant="body1">
                      <strong>Payment Status:</strong> {order.paymentStatus}
                    </Typography>
                    <Typography variant="body1">
                      <strong>Order Status:</strong> {order.orderStatus}
                    </Typography>
                    <Typography variant="body1">
                      <strong>Order Date:</strong>{' '}
                      {new Date(order.createdAt).toLocaleDateString()}
                    </Typography>
                    <Typography variant="body1">
                      <strong>Last Updated:</strong>{' '}
                      {new Date(order.updatedAt).toLocaleDateString()}
                    </Typography>
                  </CardContent>
                </Card>
              </Grid>
            </Grid>
          )}
        </Box>
      </div>
    </div>
  )
}

export default OrderDetailPage
