/* eslint-disable no-constant-condition */
/* eslint-disable no-nested-ternary */
import Button from "@mui/material/Button";
import CircularProgress from "@mui/material/CircularProgress";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import TextField from "@mui/material/TextField";
import React, { useEffect, useState } from "react";
import { toast } from 'react-toastify';
import Navbar from "../../Components/Navbar/Navbar";
import Sidebar from "../../Components/Sidebar/Sidebar";
import { baseUrl } from '../../utils/api';
import "./sliders.scss";

function Sliders() {
  const [sliderData, setSliderData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [openDialog, setOpenDialog] = useState(false);
  const [openAddModal, setOpenAddModal] = useState(false);
  const [sliderToDelete, setSliderToDelete] = useState(null);
  const [title, setTitle] = useState("");
  const [description, setDescription] = useState("");
  const [sliderImage, setSliderImage] = useState(null);

  const fallbackData = [
    {
      id: 1,
      image: "https://images.squarespace-cdn.com/content/v1/5e10bdc20efb8f0d169f85f9/09943d85-b8c7-4d64-af31-1a27d1b76698/arrow.png",
      title: "Placeholder Image 1",
      description: "This is a placeholder description for image 1.",
    },
    // Add more fallback data as needed
  ];

  const fetchSliders = async () => {
    try {
      const response = await fetch(`${baseUrl}slider`);
      if (!response.ok) throw new Error("Failed to fetch slider data");
      const data = await response.json();
      setSliderData(data.data);
    } catch (error) {
      console.error("Error fetching slider data:", error);
      setSliderData(fallbackData);
    } finally {
      setLoading(false);
    }
  };
  
  const handleOpenDialog = (id) => {
    setSliderToDelete(id);
    setOpenDialog(true);
  };
  
  const handleDelete = async () => {
    try {
      const response = await fetch(`${baseUrl +'slider/'+ sliderToDelete}`, {
        method: "DELETE",
      });
      if (!response.ok) throw new Error("Failed to delete slider");
      toast.success(`Slider deleted successfully!`);
      fetchSliders()
    } catch (error) {
      console.error("Error deleting slider:", error);
    } finally {
      setOpenDialog(false);
      setSliderToDelete(null);
    }
  };
  
  const handleAddNewSlider = async () => {
    const formData = new FormData();
    formData.append("title", title);
    formData.append("description", description);
    formData.append("sliderImage", sliderImage);
    
    try {
      const response = await fetch(`${baseUrl}slider`, {
        method: "POST",
        body: formData,
      });
      if (!response.ok) throw new Error("Failed to add slider");
      const newSlider = await response.json();
      toast.success(`Slider added successfully!`);
      fetchSliders()
    } catch (error) {
      console.error("Error adding new slider:", error);
    } finally {
      setOpenAddModal(false);
      setTitle("");
      setDescription("");
      setSliderImage(null);
    }
  };
  
  const handleImageUpload = (event) => {
    setSliderImage(event.target.files[0]);
  };

  useEffect(() => {
    fetchSliders();
  }, []);

  if (loading) {
    return (
      <div className="list_page">
        <div className="home_sidebar">
          <Sidebar />
        </div>
        <div className="list_page_main">
          <Navbar />
          <div className="loading_spinner">
            <CircularProgress />
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className="list_page">
      <div className="home_sidebar">
        <Sidebar />
      </div>
      <div className="list_page_main">
        <Navbar />
        <div className="sliders_table_container">
          <div className="table_header">
            <h2>Slider Images</h2>
            <Button variant="contained" disabled={sliderData.length > 4} color="primary" onClick={() => setOpenAddModal(true)}>
              Add New Slider
            </Button>
          </div>

          <table className="slider_table">
            <thead style={{ borderBottom: "1px solid black" }}>
              <tr>
                <th style={{ color: "black" }} >Image</th>
                <th style={{ color: "black" }}>Title</th>
                <th style={{ color: "black" }}>Description</th>
                <th style={{ color: "black" }}>Actions</th>
              </tr>
            </thead>
            <tbody>
              {sliderData.map((slider) => (
                <tr key={slider._id}>
                  <td>
                    <img src={slider.imageUrl} alt={slider.title} className="slider_image" style={{ width: "100px" }} />
                  </td>
                  <td>{slider.title}</td>
                  <td>{slider.description}</td>
                  <td>
                    <button type="button" className="delete_btn" onClick={() => handleOpenDialog(slider._id)}>
                      Delete
                    </button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>

        <Dialog open={openDialog} onClose={() => setOpenDialog(false)}>
          <DialogTitle>Confirm Deletion</DialogTitle>
          <DialogContent>
            <DialogContentText>Are you sure you want to delete this slider?</DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={() => setOpenDialog(false)} color="primary">Cancel</Button>
            <Button onClick={handleDelete} color="primary">Delete</Button>
          </DialogActions>
        </Dialog>

        <Dialog open={openAddModal} onClose={() => setOpenAddModal(false)}>
          <DialogTitle>Add New Slider</DialogTitle>
          <DialogContent>
            <TextField
              label="Title"
              fullWidth
              margin="normal"
              value={title}
              onChange={(e) => setTitle(e.target.value)}
            />
            <TextField
              label="Description"
              fullWidth
              margin="normal"
              value={description}
              onChange={(e) => setDescription(e.target.value)}
            />
            <input
              accept="image/*"
              type="file"
              onChange={handleImageUpload}
              style={{ marginTop: "20px" }}
            />
          </DialogContent>
          <DialogActions>
            <Button onClick={() => setOpenAddModal(false)} color="primary">Cancel</Button>
            <Button onClick={handleAddNewSlider} color="primary" disabled={!title || !description || !sliderImage}>
              Submit
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    </div>
  );
}

export default Sliders;
