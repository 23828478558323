/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable react/jsx-props-no-spreading */
import { Box, Card, CardContent, CircularProgress, Grid, Rating, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import Navbar from '../../Components/Navbar/Navbar';
import Sidebar from '../../Components/Sidebar/Sidebar';
import { baseUrl } from '../../utils/api';
import './Detail.scss';

function Detail() {
    const { productId } = useParams();
    const [product, setProduct] = useState(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    useEffect(() => {
        // Function to fetch product data
        const fetchProduct = async () => {
            try {
                const response = await fetch(`${baseUrl}products/${productId}/product`);
                if (!response.ok) {
                    throw new Error('Network response was not ok');
                }
                const data = await response.json();
                // console.log(JSON.stringify(data))
                setProduct(data); // Update state with product data
            } catch (err) {
                setError(err.message); // Update state with error message
            } finally {
                setLoading(false); // Set loading to false
            }
        };

        fetchProduct(); // Call the function to fetch data
    }, [productId]); // Re-run effect when the product ID changes

    // Render loading indicator or error message
    if (loading) {
        return <CircularProgress />;
    }

    return (
        <div className="details">
            <div className="home_sidebar">
                <Sidebar />
            </div>

            <div className="detail_page_main">
                <Navbar />

                <Box sx={{ padding: '20px' }}>
                   {error ? <Typography variant="body1" color="error">{`Error: ${error}`}</Typography> :  
                    <Grid container spacing={3}>
                        {/* Product Images */}
                        <Grid item xs={12} md={6}>
                            <Card className="product_card">
                                <CardContent>
                                    <div className="product_images_gallery">
                                        {product?.product?.media?.map((image, index) => (
                                            <img 
                                                key={index} 
                                                src={image} 
                                                alt={`${product?.product?.name} ${index + 1}`} 
                                                className="product_image"
                                                style={{ maxWidth: '100%', marginBottom: '10px' }}
                                            />
                                        ))}
                                        {product?.product?.media?.length == 0 &&
                                        <Typography variant="43" className="product_price">
                                        --NO IMAGE ADDED--
                                    </Typography>}
                                    
                                    </div>
                                </CardContent>
                            </Card>
                        </Grid>

                        {/* Product Information */}
                        <Grid item xs={12} md={6}>
                            <Card className="product_card">
                                <CardContent>
                                    <Typography variant="h4" component="h2" className="product_name">
                                        {product?.product?.name}
                                    </Typography>
                                    <Typography variant="h6" className="product_price">
                                        Price: ${product?.product?.price}
                                    </Typography>
                                    <Typography variant="body1" className="product_description">
                                        {product?.product?.description}
                                    </Typography>
                                    <Typography variant="body1" className="product_category">
                                        Category: {product?.product?.category?.name}
                                    </Typography>
                                    <Typography variant="body1" className="product_stock">
                                        Stock: {product?.product?.stock} items available
                                    </Typography>
                                    <Typography variant="body1" className="product_ratings" sx={{ display: 'flex', flexDirection: "row", gap: 1, alignItems: 'center' }} >
                                        <Rating value={product?.product?.ratings?.toFixed(1)} precision={0.1} readOnly />
                                        ({product?.product?.ratings?.toFixed(0)}/5)
                                    </Typography>
                                </CardContent>
                            </Card>
                        </Grid>

                        {/* Customer Reviews */}
                        <Grid item xs={12}>
                            <Card className="product_card">
                                <CardContent>
                                    <Typography variant="h5" component="h3">
                                        Customer Reviews
                                    </Typography>
                                    {product?.reviews?.length > 0 ? (
                                        product?.reviews?.map((review, index) => (
                                            <Box key={index} className="review_item" sx={{ marginBottom: '10px' }}>
                                                <Typography variant="body1">
                                                    <strong>{review?.user}</strong>: {review?.comment}
                                                </Typography>
                                                <Typography variant="body2" sx={{ display: 'flex', flexDirection: "row", gap: 1, alignItems: 'center' }}>
                                                    Rating: <Rating value={review?.rating} readOnly />
                                                </Typography>
                                            </Box>
                                        ))
                                    ) : (
                                        <Typography variant="body2">
                                            No reviews available.
                                        </Typography>
                                    )}
                                </CardContent>
                            </Card>
                        </Grid>
                    </Grid>}
                </Box>
            </div>
        </div>
    );
}

export default Detail;
