/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable react/jsx-props-no-spreading */
import {
  Avatar,
  Box,
  Button,
  Card,
  CardContent,
  CircularProgress,
  Grid,
  Rating,
  Snackbar,
  Typography,
  Tooltip,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import CancelIcon from "@mui/icons-material/Cancel";
import Navbar from "../../Components/Navbar/Navbar";
import Sidebar from "../../Components/Sidebar/Sidebar";
import { baseUrl } from "../../utils/api";
import MuiAlert from "@mui/material/Alert";
import "./MakeupArtistDetail.scss";

function MakeupArtistDetail() {
  const { makeupArtistId } = useParams();
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [snackbar, setSnackbar] = useState({
    open: false,
    message: "",
    severity: "",
  });

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(
          `${baseUrl}makeup-artists/${makeupArtistId}`
        );
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        const data = await response.json();
        setData(data);
      } catch (err) {
        setError(err.message);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [makeupArtistId]);

  const handleSnackbarClose = () => {
    setSnackbar({ open: false, message: "", severity: "" });
  };

  const updateApprovalStatus = async (value) => {
    setLoading(true);
    try {
      const response = await fetch(
        `${baseUrl}artist/${makeupArtistId}/status`,
        {
          method: "PUT",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            status: value,
          }),
        }
      );
      if (!response.ok) {
        throw new Error("Network response was not ok");
      }
      const data = await response.json();
      setSnackbar({
        open: true,
        message: `Makeup Artist ${
          value === "approve" ? "approved" : "rejected"
        } successfully!`,
        severity: "success",
      });
      setData({ ...data });
    } catch (err) {
      setSnackbar({
        open: true,
        message: err.message || "Something went wrong!",
        severity: "error",
      });
    } finally {
      setLoading(false);
    }
  };

  if (loading) {
    return (
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          minHeight: "100vh",
        }}
      >
        <CircularProgress />
      </Box>
    );
  }
  console.log(data);
  return (
    <div className="details">
      <div className="home_sidebar">
        <Sidebar />
      </div>

      <div className="detail_page_main">
        <Navbar />
        <Box sx={{ padding: "20px" }}>
          {error ? (
            <Typography variant="body1" color="error">
              {`Error: ${error}`}
            </Typography>
          ) : (
            <Grid container spacing={3}>
              {/* Makeup Artist Information */}
              <Grid item xs={12} md={6}>
                <Card
                  className="artist_card"
                  sx={{ borderRadius: 4, boxShadow: 3 }}
                >
                  <CardContent>
                    <Typography
                      variant="h5"
                      component="h2"
                      sx={{ marginBottom: 2 }}
                    >
                      Makeup Artist Details
                    </Typography>
                    <Avatar
                      sx={{
                        width: 100,
                        height: 100,
                        margin: "10px 0",
                        fontSize: 40,
                      }}
                    >
                      {data?.makeupArtist?.fullName?.charAt(0)}
                    </Avatar>
                    <Typography variant="body1">
                      <strong>Name:</strong> {data?.makeupArtist?.fullName}
                    </Typography>
                    <Typography variant="body1">
                      <strong>Email:</strong> {data?.makeupArtist?.email}
                    </Typography>
                    <Typography variant="body1">
                      <strong>Mobile:</strong>{" "}
                      {data?.makeupArtist?.mobileNumber}
                    </Typography>
                    <Typography variant="body1">
                      <strong>Location:</strong>{" "}
                      {data?.makeupArtist?.location[0]?.city}
                    </Typography>
                    <Typography variant="body1">
                      <strong>Experience:</strong>{" "}
                      {data?.makeupArtist?.experience} years
                    </Typography>
                    <Typography variant="body1" sx={{ marginTop: 1 }}>
                      <strong>Ratings:</strong>
                    </Typography>
                    <Rating
                      name="read-only"
                      value={data?.makeupArtist?.ratings}
                      readOnly
                      precision={0.5}
                    />
                  </CardContent>
                </Card>
              </Grid>

              {/* Services and Actions */}
              <Grid item xs={12} md={6}>
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    gap: 2,
                    padding: "1rem",
                    backgroundColor: "#f5f5f5",
                    borderRadius: 2,
                    boxShadow: "0 2px 4px rgba(0,0,0,0.1)",
                    width: "100%",
                    maxWidth: 400,
                    margin: "auto",
                    marginBottom: 3
                  }}
                >
                  {/* Display current status */}
                  <Typography
                    variant="h6"
                    sx={{
                      marginBottom: 1,
                      color:
                        data?.makeupArtist?.approved === "approved"
                          ? "green"
                          : data?.makeupArtist?.approved === "rejected"
                          ? "red"
                          : "gray",
                      fontWeight: "bold",
                    }}
                  >
                    Current Status:{" "}
                    {data?.makeupArtist?.approved === "approved"
                      ? "Approved"
                      : data?.makeupArtist?.approved === "rejected"
                      ? "Rejected"
                      : "Pending"}
                  </Typography>

                  {/* Conditional buttons for approving or rejecting */}
                  {data?.makeupArtist?.approved === "pending" && (
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "space-between",
                        gap: 2,
                        width: "100%",
                      }}
                    >
                      <Tooltip title="Approve this item" arrow>
                        <Button
                          type="submit"
                          onClick={() => updateApprovalStatus("approve")}
                          variant="contained"
                          color="success"
                          sx={{
                            flex: 1,
                            padding: "0.6rem 1rem",
                            borderRadius: 2,
                            fontWeight: "bold",
                            fontSize: 16,
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                            gap: 1,
                            transition: "background-color 0.3s",
                            "&:hover": {
                              backgroundColor: "#007a33",
                            },
                          }}
                        >
                          <CheckCircleIcon sx={{ fontSize: 20 }} />
                          Approve
                        </Button>
                      </Tooltip>

                      <Tooltip title="Reject this item" arrow>
                        <Button
                          type="submit"
                          onClick={() => updateApprovalStatus("reject")}
                          variant="outlined"
                          color="error"
                          sx={{
                            flex: 1,
                            padding: "0.6rem 1rem",
                            borderRadius: 2,
                            fontWeight: "bold",
                            fontSize: 16,
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                            gap: 1,
                            backgroundColor: "#fff",
                            borderColor: "#d32f2f",
                            transition: "background-color 0.3s, color 0.3s",
                            "&:hover": {
                              backgroundColor: "#d32f2f",
                              color: "#fff",
                            },
                          }}
                        >
                          <CancelIcon sx={{ fontSize: 20 }} />
                          Reject
                        </Button>
                      </Tooltip>
                    </Box>
                  )}

                  {/* Display a message when the status is approved or rejected */}
                  {data?.makeupArtist?.approved !== "pending" && (
                    <Typography variant="body2" color="textSecondary">
                      This Makeup Artist has been {data?.makeupArtist?.approved}.
                    </Typography>
                  )}
                </Box>

                <Card
                  className="artist_card"
                  sx={{ borderRadius: 4, boxShadow: 3, paddingLeft: 3 }}
                >
                  <CardContent>
                    <Typography
                      variant="h5"
                      component="h2"
                      sx={{ marginBottom: 2 }}
                    >
                      Services Offered
                    </Typography>
                    <ul>
                      {data?.makeupArtist?.services?.map((service, index) => (
                        <li key={index}>
                          <Typography variant="body1">{service}</Typography>
                        </li>
                      ))}
                    </ul>
                  </CardContent>
                </Card>
              </Grid>

              {/* Availability */}
              <Grid item xs={12}>
                <Card
                  className="artist_card"
                  sx={{ borderRadius: 4, boxShadow: 3 }}
                >
                  <CardContent>
                    <Typography
                      variant="h5"
                      component="h2"
                      sx={{ marginBottom: 2 }}
                    >
                      Availability
                    </Typography>
                    {data?.makeupArtist?.availability?.map((slot, index) => (
                      <Typography key={index} variant="body1">
                        <strong>{slot.day}:</strong> {slot?.startTime} -{" "}
                        {slot?.endTime}
                      </Typography>
                    ))}
                  </CardContent>
                </Card>
              </Grid>

              {/* Categories */}
              <Grid item xs={12}>
                <Card
                  className="artist_card"
                  sx={{ borderRadius: 4, boxShadow: 3 }}
                >
                  <CardContent>
                    <Typography
                      variant="h5"
                      component="h2"
                      sx={{ marginBottom: 2 }}
                    >
                      Specializations
                    </Typography>
                    {data?.makeupArtist?.category?.map((category, index) => (
                      <Box key={index} sx={{ marginBottom: 2 }}>
                        <Typography variant="body1">
                          <strong>{category?.name}:</strong>{" "}
                          {category?.description}
                        </Typography>
                      </Box>
                    ))}
                  </CardContent>
                </Card>
              </Grid>

              {/* Reviews */}
              <Grid item xs={12}>
                <Card
                  className="artist_card"
                  sx={{ borderRadius: 4, boxShadow: 3 }}
                >
                  <CardContent>
                    <Typography
                      variant="h5"
                      component="h2"
                      sx={{ marginBottom: 2 }}
                    >
                      Reviews
                    </Typography>
                    {data?.reviews?.length > 0 ? (
                      data?.reviews?.map((review, index) => (
                        <Box key={index} sx={{ marginBottom: 2 }}>
                          <Typography variant="body1">
                            <strong>{review?.user}:</strong> {review?.comment}
                          </Typography>
                          <Rating
                            name={`review-rating-${index}`}
                            value={review?.rating}
                            readOnly
                          />
                        </Box>
                      ))
                    ) : (
                      <Typography variant="body1">No reviews yet.</Typography>
                    )}
                  </CardContent>
                </Card>
              </Grid>
            </Grid>
          )}
        </Box>
        <Snackbar
          open={snackbar.open}
          autoHideDuration={6000}
          onClose={handleSnackbarClose}
        >
          <MuiAlert
            onClose={handleSnackbarClose}
            severity={snackbar.severity}
            sx={{ width: "100%" }}
          >
            {snackbar.message}
          </MuiAlert>
        </Snackbar>
      </div>
    </div>
  );
}

export default MakeupArtistDetail;
