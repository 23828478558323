/* eslint-disable no-underscore-dangle */
import React from 'react';
import './tableList.scss';

// MUI table components
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';

// Sample image import (you may replace this with actual images if needed)
import productImage from '../../Images/book1.jpg';
import { textData } from '../../constant/base64';

function TableList({ userData, type }) {
    const getTableHeaders = () => {
        if (type === 'product') {
            return ['Product ID', 'Product Name', 'Description', 'Price', 'Category', 'Stock', 'Ratings', 'Created At'];
        } else if (type === 'makeupArtistCategory') {
            return ['ID', 'Name', 'Description', 'Created At'];
        }
        // Add more types as needed
        return Object.keys(userData[0] || {}); // Default fallback based on data structure
    };

    const getTableRowData = (item) => {
        if (type === 'product') {
            // Check if images array exists and has at least one image
            // const productImageSrc = item.images && item.images.length > 0 ? item.images[0] : productImage;
            const productImageSrc = item.images && item.images.length > 0 ? item.images[0] : productImage;

            return (
                <>
                    <TableCell component="th" scope="row" className="table_cell">
                        <div className="product_idd">
                            <img src={textData} alt="product" className="product_img" />
                            {item.id}
                        </div>
                    </TableCell>
                    <TableCell className="table_cell">{item.name}</TableCell>
                    <TableCell className="table_cell">{item.description}</TableCell>
                    <TableCell className="table_cell">${item?.price?.toFixed(2)}</TableCell>
                    <TableCell className="table_cell">{item.category}</TableCell>
                    <TableCell className="table_cell">{item.stock}</TableCell>
                    <TableCell className="table_cell">{item?.ratings?.toFixed(1)}</TableCell>
                    <TableCell className="table_cell">{item.createdAt}</TableCell>
                </>
            );
        } else if (type === 'makeupArtistCategory') {
            return (
                <>
                    <TableCell className="table_cell">{item._id}</TableCell>
                    <TableCell className="table_cell">{item.name}</TableCell>
                    <TableCell className="table_cell">{item.description}</TableCell>
                    <TableCell className="table_cell">{new Date(item.createdAt).toLocaleString()}</TableCell>
                </>
            );
        }
        // Add more types as needed
        return Object.keys(item).map((key) => (
            <TableCell className="table_cell" key={key}>
                {item[key]}
            </TableCell>
        ));
    };

    return (
        <TableContainer component={Paper} className="table_list">
            <Table sx={{ minWidth: 650 }} aria-label="dynamic table">
                <TableHead>
                    <TableRow>
                        {getTableHeaders().map((header, index) => (
                            <TableCell key={index} className="table_cell">
                                {header}
                            </TableCell>
                        ))}
                    </TableRow>
                </TableHead>
                <TableBody>
                    {userData ? (
                        userData.map((item, index) => (
                            <TableRow key={index}>
                                {getTableRowData(item)}
                            </TableRow>
                        ))
                    ) : (
                        <div>No Data Found</div>
                    )}
                </TableBody>
            </Table>
        </TableContainer>
    );
}

export default TableList;
