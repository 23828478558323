import BarChartIcon from '@mui/icons-material/BarChart'
import CloseIcon from '@mui/icons-material/Close'
import CreditCardIcon from '@mui/icons-material/CreditCard'
import DarkModeIcon from '@mui/icons-material/DarkMode'
import DashboardIcon from '@mui/icons-material/Dashboard'
import LightModeIcon from '@mui/icons-material/LightMode'
import LogoutIcon from '@mui/icons-material/Logout'
import MenuIcon from '@mui/icons-material/Menu'
import PersonIcon from '@mui/icons-material/Person'
import TableChartIcon from '@mui/icons-material/TableChart'
import { Box } from '@mui/material'
import React, { useContext, useEffect, useState } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import { ColorContext } from '../../ColorContext/darkContext'

// import sass file
import './navbar.scss'

// import images

function Navbar() {
  const [toggle, setToggle] = useState(false)
  // color state management using react context
  const { darkMode, dispatch } = useContext(ColorContext)

  const handleToggle = () => {
    setToggle(!toggle)
  }

  const navigate = useNavigate() // Hook for navigation

  // Handle logout and clear the token from localStorage
  const handleLogout = () => {
    localStorage.removeItem('token')
    navigate('/login')
  }

  // Check for authentication token on component mount
  useEffect(() => {
    const token = localStorage.getItem('token') // Get token from localStorage

    if (!token) {
      // If no token, redirect to login page
      navigate('/login')
    }
  }, [navigate])

  return (
    <div className="navbar">
      <div className="navbar_main">
        <div className="menu_logo">
          {toggle ? (
            <CloseIcon className="menu_icon" onClick={handleToggle} />
          ) : (
            <MenuIcon className="menu_icon" onClick={handleToggle} />
          )}

          <Link to="/" style={{ textDecoration: 'none' }}>
            <h3 className="text_none">Dashboard</h3>
          </Link>
        </div>
        <div>
        </div>

        <div className="item_lists">
          {/* <div className="item item_lan">
            <LanguageIcon className="item_icon" />
            <p>English</p>
          </div> */}
          <div className="item">
            {!darkMode ? (
              <DarkModeIcon
                className="item_icon"
                onClick={() => dispatch({ type: 'TOGGLE' })}
              />
            ) : (
              <LightModeIcon
                className="item_icon white"
                onClick={() => dispatch({ type: 'TOGGLE' })}
              />
            )}
          </div>
          {/* <div className="item">
            <FullscreenExitIcon className="item_icon" />
          </div> */}

          {/* <div className="item">
            <ChatBubbleOutlineIcon className="item_icon" />
            <span className="badge">2</span>
          </div> */}
          {/* <div className="item">
            <NotificationsNoneIcon className="item_icon" />
            <span className="badge">1</span>
          </div> */}

          {/* <div className="item">
            <img className="admin_pic" src={admin} alt="admin" />
          </div> */}
        </div>
      </div>

      <div className="res_navbar">
        {toggle && (
          <div className="res_nav_menu">
            <div className="res_nav_menuu">
              <div className="links">
                <ul>
                  <p className="spann">Main</p>
                  <Link to="/" style={{ textDecoration: 'none' }}>
                    <li>
                      <DashboardIcon className="icon" /> Dashboard
                    </li>
                  </Link>

                  <p className="spann">Lists</p>
                  <Link to="/users" style={{ textDecoration: 'none' }}>
                    <li>
                      <PersonIcon className="icon" /> Users
                    </li>
                  </Link>

                  <Link to="/products" style={{ textDecoration: 'none' }}>
                    <li>
                      <TableChartIcon className="icon" /> Products
                    </li>
                  </Link>
                  <Link to="/orders" style={{ textDecoration: 'none' }}>
                    <li>
                      <CreditCardIcon className="icon" /> Orders
                    </li>
                  </Link>
                  <Link to="/sliders" style={{ textDecoration: 'none' }}>
                    <li>
                      <CreditCardIcon className="icon" /> Sliders
                    </li> 
                  </Link>
                  <Link to="/categories" style={{ textDecoration: 'none' }}>
                    <li>
                      <CreditCardIcon className="icon" /> Categories
                    </li>
                  </Link>
                  <Link to="/makeupArtist" style={{ textDecoration: 'none' }}>
                    <li>
                      <BarChartIcon className="icon" /> Makeup Artist
                    </li>
                  </Link>
                  <Link
                    to="/makeupArtistCategories"
                    style={{ textDecoration: 'none' }}
                  >
                    <li>
                      <BarChartIcon className="icon" /> Makeup Artist Categories
                    </li>
                  </Link>

                  <p className="spann">Settings</p>
                  {/* <li>
                    <AccountCircleIcon className="icon" /> Profile
                  </li>
                  <li>
                    <SettingsRoundedIcon className="icon" /> Setting
                  </li> */}

                  {/* Use a button inside the <li> for Log Out */}
                  <Box onClick={handleLogout}>
                    <li>
                      <LogoutIcon className="icon" /> Log Out
                    </li>
                  </Box>
                </ul>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  )
}

export default Navbar
