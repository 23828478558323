import { useContext } from 'react'
import { BrowserRouter, Route, Routes } from 'react-router-dom'
import { ColorContext } from './ColorContext/darkContext'
import Home from './Components/Home/Home'
import Orders from './Components/Orders/Orders'
import AddNew from './Pages/AddNew/AddNew'
import BlogDetail from './Pages/BlogDetail/BlogDetail'
import Blogs from './Pages/Blogs/Blogs'
import Detail from './Pages/Detail/Detail'
import Login from './Pages/Login/Login'
import Lists from './Pages/UserLists/UserLists'
import './app.scss'
import ProductsList from './Pages/Products/Products'
import MakeupArtist from './Pages/MakeupArtist/MakeupArtist'
import MakeupArtistCategories from './Pages/MakeupArtistCategories/MakeupArtistCategories'
import { ToastContainer } from 'react-toastify'
import Categories from './Pages/Categories/Categories'
import OrderDetailPage from './Pages/OrderDetail/OderDetailPage'
import MakeupArtistDetail from './Pages/MakeupArtistDetail/MakeupArtistDetail'
import Sliders from './Pages/Sliders/Sliders'

// Dynamicaly change the data for different pages(replaceable)
const userInpDetails = [
  {
    id: 2,
    name: 'username',
    lable: 'Username',
    type: 'text',
    placeholder: 'John23',
    required: true,
    pattern: '^[A-Za-z0-9]{3,12}$',
    errorMsg:
      'Username should be 3-12 characters & should not include any special character!'
  },
  {
    id: 3,
    name: 'name',
    lable: 'Name',
    type: 'text',
    placeholder: 'John Smith',
    required: true,
    pattern: '^[A-Za-z]{1,20}$',
    errorMsg: 'Name is required!'
  },
  {
    id: 4,
    name: 'email',
    lable: 'Email',
    type: 'email',
    placeholder: 'example@email.com',
    required: true,
    errorMsg: 'Enter a valid email!'
  },
  {
    id: 5,
    name: 'password',
    lable: 'Password',
    type: 'password',
    placeholder: 'Password',
    required: true,
    pattern:
      '^(?=.*[0-9])(?=.*[A-Za-z])(?=.*[!@#$%^&*()_+])[A-Za-z0-9!@#$%^&*()_+]{6,20}$',
    errorMsg:
      'Password should be 6-20 characters and include at last 1 num, 1 letter, 1 special character!'
  },
  {
    id: 6,
    name: 'address',
    lable: 'Address',
    type: 'text',
    placeholder: 'Address',
    required: true,
    errorMsg: 'Address is required!'
  }
]

const productInpDetails = [
  {
    id: 2,
    name: 'name',
    lable: 'Title',
    type: 'text',
    placeholder: 'Product title',
    required: true,
    errorMsg: 'Title is required!'
  },
  {
    id: 3,
    name: 'description',
    lable: 'Description',
    type: 'text',
    placeholder: 'Product description',
    required: true,
    errorMsg: 'Description is required!'
  },
  {
    id: 4,
    name: 'category',
    lable: 'Category',
    type: 'text',
    placeholder: 'Product category',
    required: true,
    errorMsg: 'Category is required!'
  },
  {
    id: 4,
    name: 'productType',
    lable: 'Product Type',
    type: 'text',
    placeholder: 'Product category',
    required: true,
    errorMsg: 'Product Type is required!'
  },
  {
    id: 5,
    name: 'price',
    lable: 'Price',
    type: 'number',
    placeholder: 'Product price',
    required: true,
    errorMsg: 'Price is required!'
  },
  {
    id: 6,
    name: 'stock',
    lable: 'In Stock',
    type: 'number',
    placeholder: 'In Stock',
    required: true,
    errorMsg: 'This field is required!'
  }
]

const blogInputs = [
  {
    id: 1,
    name: 'title',
    lable: 'Title',
    type: 'text',
    placeholder: 'Blog title',
    required: true,
    errorMsg: 'Title is required!'
  },
  {
    id: 2,
    name: 'description',
    lable: 'Description',
    type: 'text',
    placeholder: 'Blog description',
    required: true,
    errorMsg: 'Description is required!'
  },
  {
    id: 3,
    name: 'tags',
    lable: 'Tags',
    type: 'text',
    placeholder: 'Travel, Communication',
    required: true,
    errorMsg: 'Tag is required!'
  }
]

const caregoriesInputs = [
  {
    id: 1,
    name: 'name',
    lable: 'Title',
    type: 'text',
    placeholder: 'Blog title',
    required: true,
    errorMsg: 'Title is required!'
  },
  {
    id: 2,
    name: 'description',
    lable: 'Description',
    type: 'text',
    placeholder: 'Blog description',
    required: true,
    errorMsg: 'Description is required!'
  }
]

const makeupArtistInputs = [
  {
    id: 1,
    name: 'fullName',
    lable: 'Full Name',
    type: 'text',
    placeholder: 'Enter full name',
    required: true,
    errorMsg: 'Full Name is required!'
  },
  {
    id: 2,
    name: 'email',
    lable: 'Email',
    type: 'email',
    placeholder: 'Enter email address',
    required: true,
    errorMsg: 'Email is required!'
  },
  {
    id: 3,
    name: 'phoneNumber',
    lable: 'Phone Number',
    type: 'tel',
    placeholder: 'Enter phone number',
    required: true,
    errorMsg: 'Phone number is required!'
  },
  {
    id: 4,
    name: 'appreciation',
    lable: 'Appreciation',
    type: 'number',
    placeholder: 'Enter appreciation count',
    required: true,
    errorMsg: 'Appreciation count is required!'
  },
  {
    id: 5,
    name: 'tasksCompleted',
    lable: 'Tasks Completed',
    type: 'number',
    placeholder: 'Enter number of tasks completed',
    required: true,
    errorMsg: 'Tasks completed is required!'
  },
  {
    id: 6,
    name: 'rewards',
    lable: 'Rewards',
    type: 'number',
    placeholder: 'Enter number of rewards',
    required: true,
    errorMsg: 'Rewards count is required!'
  },
  {
    id: 7,
    name: 'experience',
    lable: 'Experience (Years)',
    type: 'number',
    placeholder: 'Enter years of experience',
    required: true,
    errorMsg: 'Experience is required!'
  },
  {
    id: 8,
    name: 'services',
    lable: 'Services Offered',
    type: 'text',
    placeholder: 'Enter services offered (comma separated)',
    required: true,
    errorMsg: 'Services are required!'
  },
  {
    id: 9,
    name: 'availability',
    lable: 'Availability',
    type: 'text',
    placeholder: 'Enter availability (e.g., Friday 09:30 AM - 06:30 PM)',
    required: true,
    errorMsg: 'Availability is required!'
  },
  {
    id: 10,
    name: 'location',
    lable: 'Location',
    type: 'text',
    placeholder: 'Enter location',
    required: true,
    errorMsg: 'Location is required!'
  },
  {
    id: 11,
    name: 'category',
    lable: 'Category',
    type: 'text',
    placeholder: 'Enter category IDs (comma separated)',
    required: true,
    errorMsg: 'Category is required!'
  }
]


function App() {
  // color state management using react context
  const { darkMode } = useContext(ColorContext)

  return (
    <div className={darkMode ? 'App dark' : 'App'}>
      {/* Toast container */}
      <ToastContainer
        position="top-right"
        autoClose={3000}
        hideProgressBar={false}
        closeOnClick
        pauseOnHover
        draggable
      />
      <BrowserRouter>
        <Routes>
          <Route path="/">
            <Route index element={<Home />} />
            <Route path="login" element={<Login />} />
            {/* nested routes */}
            <Route path="users">
              <Route index element={<Lists type="user" />} />
              <Route path=":userId" element={<Detail />} />
              <Route
                path="addnew"
                element={
                  <AddNew
                    inputs={userInpDetails}
                    titlee="Add New User"
                    type="USER"
                  />
                }
              />
            </Route>

            {/* <Route path="orders" element={<Orders />} /> */}
            <Route path="orders">
              <Route index element={<Orders />} />
              <Route path=":orderId" element={<OrderDetailPage />} />
            </Route>

            <Route path="sliders">
              <Route index element={<Sliders />} />
            </Route>

            {/* nested routes */}
            <Route path="products">
              <Route index element={<ProductsList type="product" />} />
              <Route path=":productId" element={<Detail />} />
              <Route
                path="addnew"
                element={
                  <AddNew
                    inputs={productInpDetails}
                    titlee="Add New Product"
                    type="PRODUCT"
                  />
                }
              />
            </Route>

            <Route path="categories">
              <Route index element={<Categories type="categories" />} />
              <Route path=":categoriesId" element={<Detail />} />
              <Route
                path="addnew"
                element={
                  <AddNew
                    inputs={caregoriesInputs}
                    titlee="Add New Category"
                    type="CATEGORIES"
                  />
                }
              />
            </Route>

            <Route path="makeupArtist">
              <Route index element={<MakeupArtist type="makeupArtist" />} />
              <Route path=":makeupArtistId" element={<MakeupArtistDetail />} />
              <Route
                path="addnew"
                element={
                  <AddNew
                    inputs={makeupArtistInputs}
                    titlee="Add New Makeup Artist"
                    type="MAKEUP_ARTIST"
                  />
                }
              />
            </Route>

            <Route path="makeupArtistCategories">
              <Route
                index
                element={
                  <MakeupArtistCategories type="makeupArtistCategories" />
                }
              />
              <Route path=":makeupArtistCategoriesId" element={<Detail />} />
              <Route
                path="addnew"
                element={
                  <AddNew
                    inputs={blogInputs}
                    titlee="Add New Makeup Artist Categories"
                    type="BLOG"
                  />
                }
              />
            </Route>
          </Route>
        </Routes>
      </BrowserRouter>
    </div>
  )
}

export default App
