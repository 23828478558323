/* eslint-disable jsx-a11y/no-static-element-interactions */
import BarChartIcon from '@mui/icons-material/BarChart'
import CreditCardIcon from '@mui/icons-material/CreditCard'
import DashboardIcon from '@mui/icons-material/Dashboard'
import LogoutIcon from '@mui/icons-material/Logout'
import PersonIcon from '@mui/icons-material/Person'
import TableChartIcon from '@mui/icons-material/TableChart'
import { Box } from '@mui/material'
import React, { useContext } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import { ColorContext } from '../../ColorContext/darkContext'
import './Sidebar.scss'

function Sidebar() {
  const navigate = useNavigate()
  const { darkMode, dispatch } = useContext(ColorContext)

  // Handle logout and clear the token from localStorage
  const handleLogout = () => {
    localStorage.removeItem('token')
    navigate('/login')
  }

  return (
    <div className="sidebar">
      <div className="logo">
        <Link to="/" style={{ textDecoration: 'none' }}>
          <h3 className="text_none">AdminDashboard</h3>
        </Link>
      </div>

      <div className="links">
        <ul>
          <p className="spann">Main</p>
          <Link to="/" style={{ textDecoration: 'none' }}>
            <li>
              <DashboardIcon className="icon" /> Dashboard
            </li>
          </Link>

          <p className="spann">Lists</p>
          <Link to="/users" style={{ textDecoration: 'none' }}>
            <li>
              <PersonIcon className="icon" /> Users
            </li>
          </Link>

          <Link to="/products" style={{ textDecoration: 'none' }}>
            <li>
              <TableChartIcon className="icon" /> Products
            </li>
          </Link>
          <Link to="/orders" style={{ textDecoration: 'none' }}>
            <li>
              <CreditCardIcon className="icon" /> Orders
            </li>
          </Link>
          <Link to="/sliders" style={{ textDecoration: 'none' }}>
            <li>
              <CreditCardIcon className="icon" /> Sliders
            </li>
          </Link>
          <Link to="/categories" style={{ textDecoration: 'none' }}>
            <li>
              <CreditCardIcon className="icon" /> Categories
            </li>
          </Link>
          <Link to="/makeupArtist" style={{ textDecoration: 'none' }}>
            <li>
              <BarChartIcon className="icon" /> Makeup Artist
            </li>
          </Link>
          <Link to="/makeupArtistCategories" style={{ textDecoration: 'none' }}>
            <li>
              <BarChartIcon className="icon" /> Makeup Artist Categories
            </li>
          </Link>

          <p className="spann">Settings</p>
          {/* <li>
            <AccountCircleIcon className="icon" /> Profile
          </li>
          <li>
            <SettingsRoundedIcon className="icon" /> Setting
          </li> */}

          {/* Use a button inside the <li> for Log Out */}
          <Box onClick={handleLogout}>
            <li>
              <LogoutIcon className="icon" /> Log Out
            </li>
          </Box>
        </ul>
      </div>
    </div>
  )
}

export default Sidebar
