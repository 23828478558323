import { Box, Button, Card, CardContent, Grid, TextField, Typography } from '@mui/material';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify'; // Import Toastify components
import 'react-toastify/dist/ReactToastify.css'; // Import Toastify styles
import companyLogo from '../../Images/makeuplines_logo.png'; // Adjust the path to your logo
import { baseUrl } from '../../utils/api';

// Custom theme with primary color set to #4665fdde
const theme = createTheme({
  palette: {
    primary: {
      main: '#4665fdde',
    },
  },
});

function Login() {
  // State for user input
  const [userInp, setUserInp] = useState({
    email: '', // Changed from username to email
    password: ''
  });

  // State to handle errors for fields
  const [errors, setErrors] = useState({
    email: '', // Changed from username to email
    password: ''
  });

  const navigate = useNavigate();

  // Form field definitions
  const formFields = [
    {
      label: 'Email', // Changed label to Email
      name: 'email', // Changed name to email
      pattern: '^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\\.[a-zA-Z]{2,}$', // Regex pattern for email
      placeholder: 'example@mail.com',
      errorMsg: 'Please enter a valid email address!',
      required: true,
      type: 'email', // Changed type to email
    },
    {
      label: 'Password',
      name: 'password',
      pattern: '^(?=.*[0-9])(?=.*[a-z])(?=.*[A-Z]).{6,20}$',
      placeholder: 'Password123',
      errorMsg: 'Password should be 6-20 characters & must include at least one number, one uppercase and one lowercase letter.',
      required: true,
      type: 'password',
    }
  ];

  // Handle input change
  const handleChange = (e) => {
    setUserInp({ ...userInp, [e.target.name]: e.target.value });
    setErrors({ ...errors, [e.target.name]: '' }); // Clear error on input change
  };

  // Validate inputs
  const validateInputs = () => {
    let valid = true;
    const newErrors = {};

    formFields.forEach((field) => {
      const regex = new RegExp(field.pattern);
      if (!regex.test(userInp[field.name])) {
        valid = false;
        newErrors[field.name] = field.errorMsg;
      }
    });

    setErrors(newErrors);
    return valid;
  };

  // Handle form submission
  const handleSubmit = async (e) => {
    e.preventDefault();

    if (validateInputs()) {
      const { email, password } = userInp;

      try {
        const response = await fetch(`${baseUrl}login`, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            email: email, // Use email for the email field
            password: password,
            query: "4" // Adjust as necessary
          }),
        });
        
        if (response.ok) {
          const data = await response.json();
          console.log(data)
          // Save token to localStorage
          localStorage.setItem('token', data.token);

          toast.success("Login successful!"); // Show success message
          navigate('/'); // Navigate after successful login
        } else {
          const errorData = await response.json();
          console.error("Login failed:", errorData);
          setErrors({ ...errors, email: 'Login failed. Please check your credentials.' });
          toast.error("Login failed. Please check your credentials."); // Show error message
        }
      } catch (error) {
        console.error("Error during login:", error);
        setErrors({ ...errors, email: 'An error occurred. Please try again later.' });
        toast.error("An error occurred. Please try again later."); // Show network error message
      }
    }
  };

  useEffect(() => {
    const token = localStorage.getItem('token');
    if (token) {
      // If token exists, redirect to homepage or another page
      navigate('/');
    }
  }, [navigate]);

  return (
    <ThemeProvider theme={theme}>
      {/* <ToastContainer position="top-right" autoClose={3000} hideProgressBar={false} /> Toast container */}
      <Grid 
        container 
        justifyContent="center" 
        alignItems="center" 
        sx={{ minHeight: '100vh', backgroundColor: '#f0f0f0' }}
      >
        <Grid item xs={11} sm={8} md={4} position="relative">
          {/* Logo placed here */}
          <Box 
            component="img"
            src={companyLogo}
            alt="Company Logo"
            sx={{
              position: 'absolute',
              top: '-5px', // Adjust this to center the logo
              left: '50%',
              transform: 'translateX(-50%)',
              width: '120px',  // Adjust size of logo as needed
              height: '120px',
              zIndex: 1,
            }}
          />
          
          <Card elevation={3} sx={{ padding: '2rem', borderRadius: '8px', position: 'relative', marginTop: '60px' }}>
            <CardContent>
              <Typography variant="h5" align="center" gutterBottom>
                Login
              </Typography>

              <form onSubmit={handleSubmit}>
                {formFields.map((field) => (
                  <Box key={field.name} mb={2}>
                    <TextField
                      fullWidth
                      label={field.label}
                      name={field.name}
                      type={field.type}
                      value={userInp[field.name]}
                      onChange={handleChange}
                      placeholder={field.placeholder}
                      required={field.required}
                      error={Boolean(errors[field.name])}
                      helperText={errors[field.name]}
                    />
                  </Box>
                ))}

                <Box textAlign="center">
                  <Button 
                    type="submit" 
                    variant="contained" 
                    color="primary" // Primary button color
                    sx={{ width: '60%', padding: '0.5rem', borderRadius: 18, color: 'white', fontWeight: 'bold', fontSize: 16 }}
                  >
                    Submit
                  </Button>
                </Box>
              </form>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </ThemeProvider>
  );
}

export default Login;
